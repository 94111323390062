var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索条件" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "核销码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索条件" },
                    model: {
                      value: _vm.searchInfo.verifyCode,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "verifyCode", $$v)
                      },
                      expression: "searchInfo.verifyCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增店铺")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { label: "店铺ID", prop: "ID" } }),
          _c("el-table-column", { attrs: { label: "店铺名", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "logo", prop: "logo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.logo, fit: "contain" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "shopType", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.shopType == 1
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("单店")
                        ])
                      : _vm._e(),
                    scope.row.shopType == 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("连锁店")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "核销码", prop: "verifyCode", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateEquityShop(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteEquityShop(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑店铺" : "创建店铺"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "shopForm",
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "80px",
                rules: _vm.shopRule
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺名:", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入",
                              maxlength: "16",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型:", prop: "shopType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formData.shopType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "shopType", $$v)
                                },
                                expression: "formData.shopType"
                              }
                            },
                            _vm._l(_vm.shopoptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.disabled
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "核销码:", prop: "verifyCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入",
                              maxlength: "8",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.formData.verifyCode,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "verifyCode", $$v)
                              },
                              expression: "formData.verifyCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.formData.shopType == 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "连锁店:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    "remote-method": _vm.getParentShops
                                  },
                                  model: {
                                    value: _vm.formData.parentId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "parentId", $$v)
                                    },
                                    expression: "formData.parentId"
                                  }
                                },
                                _vm._l(_vm.parentShops, function(item) {
                                  return _c("el-option", {
                                    key: item.ID,
                                    attrs: {
                                      label: item.name,
                                      value: item.ID,
                                      disabled: item.disabled
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "LOGO:", prop: "logo" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleAvatarSuccess
                      }
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.childShops,
                    expression: "childShops"
                  }
                ],
                attrs: { type: "hidden" },
                domProps: { value: _vm.childShops },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.childShops = $event.target.value
                  }
                }
              }),
              _vm.formData.shopType === 2 && _vm.formData.parentId == 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addChildShop }
                    },
                    [_vm._v("添加分店")]
                  )
                : _vm._e(),
              _vm._l(_vm.childShops, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "请输入分店名",
                                maxlength: "16",
                                "show-word-limit": ""
                              },
                              model: {
                                value: item.name,
                                callback: function($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "请输入核销码",
                                maxlength: "8",
                                "show-word-limit": ""
                              },
                              model: {
                                value: item.verifyCode,
                                callback: function($$v) {
                                  _vm.$set(item, "verifyCode", $$v)
                                },
                                expression: "item.verifyCode"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteChild(index)
                              }
                            }
                          },
                          [_vm._v("X")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }